import { defineMessages } from "react-intl";
export default defineMessages({
  confirm: {
    id: "a2vote.confirm",
    defaultMessage: "Confirm"
  },
  cancel: {
    id: "a2vote.cancel",
    defaultMessage: "Cancel"
  },
  validate: {
    id: "a2vote.validate.suppression",
    defaultMessage: "Delete confirmation"
  },
  description: {
    id: "a2vote.description.suppression",
    defaultMessage: "Do you confirm the deletion of this list ?"
  }
});