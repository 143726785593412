import React from "react";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
// styled
import {
  Buttons,
  PopUp,
  Card,
  Title,
  Description,
  Button,
  ButtonRed
} from "./styled";

const ConfirmDelete = ({ handleDeleteTrue, handleDeleteFalse}) => {
  return (
    <PopUp>
      <Card>
        <Title>
          <FormattedMessage {...messages.validate} />
        </Title>
        <Description>
          <FormattedMessage {...messages.description} />
        </Description>
        <Buttons>
          <ButtonRed onClick={()=>handleDeleteTrue()}>
            <FormattedMessage {...messages.confirm} />
          </ButtonRed>
          <Button onClick={()=>handleDeleteFalse()}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Buttons>
      </Card>
    </PopUp>
  )
}

export default ConfirmDelete;