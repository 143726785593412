import styled from "styled-components";
import theme from "theme";

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;


`;

export const Button = styled.button`
  background: ${theme.white};
  padding: 8px;
  border: 1px solid ${theme.darkgray};
  color: ${theme.darkgray};
  border-radius: 6px;
  transition: all .3s;
  cursor: pointer;
  font-size: 16px;
  width: 33%;

  ${props => !props.disable && `
    &:hover {
      background-color: ${theme.darkgray};
      color: ${theme.white};
    }
  `}

  @media(max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonRed = styled(Button)`
  border-color: ${theme.lightred};
  color: ${theme.lightred};

  ${props => !props.disable && `
    &:hover {
      background-color: ${theme.lightred};
      color: ${theme.white};
    }
  `}

  ${props => props.disable && `
    color: ${theme.gray};
    border-color: ${theme.gray};
    cursor: default;
  `}

  ${props => props.selected && `
    background: ${theme.lightred};
    border-color: ${theme.lightred};
    color: ${theme.white};
  `}
`;

export const PopUp = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${theme.specific.blue.opacity};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  `;

  export const Card = styled.div`
  background-color: ${theme.white};
  padding: 48px;
  border-radius: 16px;
  width: 40%;
  text-align: center;
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${theme.darkgray};
`;

export const Description = styled.div`
  color: ${theme.mediumgray};
`;